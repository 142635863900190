<template>
  <form @submit.prevent="onSubmitForm">
    <h4 class="mb-2">
      Please share your reason for leaving
    </h4>

    <div
      v-for="(reason, key) in reasons"
      :key="key">
      <div class="flex items-start gap-2 mb-6">
        <input
          :id="`reason_${key}`"
          v-model="reasonCancellations.reasons[key]"
          type="checkbox"
          class="mt-1"
          @change="resetIssues($event, key)">
        <div class="flex flex-col">
          <label
            :for="`reason_${key}`"
            class="m-0 text-base font-bold">
            {{ reason.labelCaption }}
          </label>
          <div class="flex flex-col gap-1 mt-2 text-sm">
            <span class="font-medium">{{ reason.title }}</span>
            <!-- eslint-disable vue/no-v-html -->
            <p
              class="mb-0"
              v-html="reason.description" />
            <!-- eslint-enable vue/no-v-html -->
            <div
              v-for="issue in reason.issuesList"
              :key="issue.value"
              class="flex items-center gap-2">
              <input
                :id="`reason_${key}_issue_${issue.value}`"
                v-model="reasonCancellations.reasons[`${key}_issues`]"
                :value="issue.value"
                type="checkbox"
                @input.prevent="setIssues($event, key)">
              <label :for="`reason_${key}_issue_${issue.value}`">{{ issue.title }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span>
      If you have any feedback, we would love for you to leave a comment below.
    </span>
    <textarea
      id=""
      v-model="reasonCancellations.feedback"
      class="form-control" />

    <div class="mt-4">
      <strong class="m-0 text-base font-bold">What is the likelihood of you returning in the future?</strong>
      <div class="flex gap-1 mt-2 text-xl text-yellow-400">
        <i
          v-for="n in 10"
          :key="n"
          :class="['fa-star', { fas: reasonCancellations.rank >= n, far: reasonCancellations.rank < n }]"
          @click="setRank(n)" />
      </div>
    </div>

    <div class="mt-16 flex justify-between items-center">
      <a
        type="button"
        class="btn btn-primary"
        href="/subscriptions">
        Back to Billing
      </a>

      <button
        class="btn btn-light-gray"
        :disabeled="isRequesting">
        Cancel plan
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'
import securedAxios from '~/init/axios'
import { runSwal } from '../../common/delete_with_swal'

const props = defineProps({
  subscriptionId: { type: String, required: true }
})

const reasons = {
  bug: {
    labelCaption: 'I had an issue with functionality',
    title: 'Something not working how it should be?',
    description: `We have a very active development team and aim to resolve any software issues as quickly as possible.
      Select the feature(s) you had trouble with and let us know in the comment section on this form.`,
    issuesList: [
      {
        value: 'geogrids',
        title: 'geogrids'
      },
      {
        value: 'locations',
        title: 'locations'
      },
      {
        value: 'post',
        title: 'post'
      }
    ]
  },
  functionality: {
    labelCaption: 'Lack of functionality',
    title: 'We are constantly developing new features',
    description: `Are we missing something you need? Please
      <a href="mailto:support@linda.co?subject=Question%20about%20Linda" class="underline text-blue-600" target="_blank">
      message us</a> and let us know what you need.`,
    issuesList: []
  },
  support: {
    labelCaption: 'I had issues with customer support',
    title: 'Customer service didn\'t meet your expectations?',
    description: `We take great pride in helping our customers as quickly as possible and
      delivering an excellent customer support experience. If we fell short of that goal,
      we'd love to have a quick chat with you and see how we can do better.`,
    issuesList: []
  },
  cost: {
    labelCaption: 'Out of budget or downsizing',
    title: 'Talk to us - we may be able to help',
    description: `We know sometimes you have to make tough decisions for your business, but at Linda,
      we're committed to helping our customers in times of need.`,
    issuesList: []
  },
  complicated: {
    labelCaption: 'It was too complicated for me',
    title: 'Tell us how we can make things simpler',
    description: `We try to make Linda as easy to use as possible,
      so if you find something that is confusing or complicated, let us know.`,
    issuesList: []
  },
  time: {
    labelCaption: 'I had no time to use the software',
    title: 'Not finding enough time to use the software?',
    description: `We know you're busy running your business,
      and Linda was built to make things easier and automated.
      Let us know the problem(s) you ran into so we can get to work on the solution(s)`,
    issuesList: []
  },
  closed: {
    labelCaption: 'My business is closing',
    title: 'We are sad to see you go',
    description: `We know running a business of any size can be difficult, and we're sad to see you go.
      If you have any feedback, we would love for you to leave a comment on this page below.`,
    issuesList: []
  },
  other: {
    labelCaption: 'Other',
    title: 'I have my own reason',
    description: `We work to make our product better and more convenient every day.
      Let us know what you had a problem with so we'll know where to focus our efforts.`,
    issuesList: []
  }
}

const isRequesting = ref(false)
const reasonCancellations = ref({
  reasons: {},
  rank: null,
  feedback: ''
})

const setIssues = (event, key) => {
  reasonCancellations.value.reasons[key] = true

  let issues = reasonCancellations.value.reasons[`${key}_issues`]

  if (!Array.isArray(issues)) {
    issues = []
  }

  if (issues.includes(event.target.value)) {
    issues = issues.filter((elem) => elem !== event.target.value)
  } else {
    issues.push(event.target.value)
  }

  reasonCancellations.value.reasons[`${key}_issues`] = issues
}

const resetIssues = (event, key) => {
  if (event.target.checked) return

  reasonCancellations.value.reasons[`${key}_issues`] = []
}

const setRank = (n) => {
  reasonCancellations.value.rank = n
}

const validateForm = (data) => {
  let valid = true
  const errorMessages = []

  if (!Object.keys(data.reasons).filter((key) => typeof data.reasons[key] === 'boolean' && data.reasons[key]).length) {
    errorMessages.push('Please select a reason.')
    valid = false
  }

  if (!data.rank) {
    errorMessages.push('Please select the likelihood of return.')
    valid = false
  }

  if (errorMessages.length) {
    mdtoast.error(errorMessages.join('<br>'))
  }

  return valid
}

const cancelSubscription = async() => {
  try {
    const subscriptionCancellation = reasonCancellations.value
    await securedAxios.post('/ajax/subscriptions/cancellations', {
      subscriptionId: props.subscriptionId,
      subscriptionCancellation: subscriptionCancellation
    })
    window.location.assign('/subscriptions')
  } catch (error) {
    isRequesting.value = false
    if (error?.response?.status === 422) {
      mdtoast.error(error.response.data.error)
    } else {
      mdtoast.error(error.message)
    }
  }
}

const onSubmitForm = () => {
  if (!validateForm(reasonCancellations.value)) return
  isRequesting.value = true
  runSwal(cancelSubscription, {
    content: `I understand that by proceeding, I will lose access to my rank tracking, geogrid,
      and post data, as well as my entire account history when my paid period ends.`
  })
}

</script>
