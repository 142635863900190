import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import SubscriptionDescription from '~/vue_widgets/subscriptions/subscription_cancellation.vue'

export default class extends Controller {
  static values = {
    subscriptionId: String
  }

  connect() {
    this.vue = createApp(SubscriptionDescription, { subscriptionId: this.subscriptionIdValue })
    this.vue.mount('#vue-subscription-cancellation')
  }

  disconnect() {
    this.vue?.unmount()
  }
}
